<template>
   <div class="tougao">
      <i-header></i-header>
      <div class="contribute scrol">
         <h4><img src="../assets/images/tgzn.png">投稿指南</h4>
         <div class="manner">
            <div><p>本数据库用户享有在我社国家级电子期刊上优先发表文章的权利，待出刊后可在万方数据库平台查询。最终解释权归中国大百科全书出版社所有。</p></div>
            <div>
               《百科论坛电子杂志》是国家新闻出版署批准的连续型电子出版物（CD-ROM），国内统一连续出版物号为CN11-9373/Z，中文，半月刊。</br>
                  <label>
                     出版单位：中国大百科全书电子音像出版社</br>
                     主办单位：中国大百科全书出版社有限公司</br>
                     主管单位：中国出版传媒股份有限公司</br>
                     咨询电话：010-52668165</br>
                     投稿邮箱：baikeluntantougao@126.com</br>
                  </label>
               </p>
            </div>
            <div>
               <p>《文渊（小学版、初中版、高中版）》是国家新闻出版署批准的连续型电子出版物（CD-ROM），国内统一连续出版物号分别为CN11-9274/G、CN11-9275/G、CN11-9276/G，中文，月刊。</br>
                  <label>
                     出版单位：中国大百科全书出版社有限公司</br>
                     主办单位：中国出版传媒股份有限公司</br>
                     主管单位：中国出版传媒股份有限公司</br>
                     咨询电话：010-52668007</br>
                     投稿邮箱：wenyuantougaoyx@163.com</br>
                  </label>
               </p>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
  import iHeader from '@/components/Header.vue';
  export default {
     name: "guidelines",
     components: {
        iHeader,
     },
    data(){
      return{

      }
    }
  }
</script>
<style scoped>
.tougao{
   min-height: 100%;
   background-image: url("../assets/images/details/bg.png");
   background-size: 100% 100%;
}
.tougao >>> .menu_bar{
   display: none;
}
.contribute{
   width: 900px;
   margin: auto;
   background: rgba(255,255,255,.75);
   height: 100vh;
   padding: 70px 60px;
   box-sizing: border-box;
   text-align: left;
   overflow-y: auto;
}
.contribute h4{
   font-size: 30px;
   color: #333333;
   padding-bottom: 25px;
   border-bottom: 1px solid #B3B3B3;
}
.contribute h4 img{
   width: 39px;
   height: 29px;
   margin-right: 12px;
   vertical-align: text-bottom;
   margin-bottom: 3px;
}
.manner{
   color: #333333;
   font-size: 16px;
   line-height: 32px;
   padding-top: 30px;
}
.manner div{
   margin-bottom: 30px;
}
.manner p{
   text-indent: 32px;
}
</style>
